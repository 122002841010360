<template>
  <div class="container">
    <h2 class="text-blue text-center">Messages</h2>
    <div class="row">
      <div
        v-for="(message, index) in getMessages"
        :key="index"
        class="col-md-4 col-sm-6"
      >
        <message-card :message="message" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import MessageCard from "./MessageCard.vue";

export default {
  name: "Messages",
  components: { MessageCard },
  computed: { ...mapGetters(["getMessages"]) },
};
</script>

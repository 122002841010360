<template>
  <div id="app">
    <vue-headful title="Admin | SGC" />
    <top-nav />
    <router-view />
  </div>
</template>


<script>
import { auth, db } from "./firebase";

import TopNav from "./components/TopNav.vue";

export default {
  name: "App",
  components: { TopNav },
  created() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.$store.commit("userStatus", { uid: user.uid, email: user.email });
        const res = await this.$store.dispatch("getProfile", user.uid);
        if (!res.isValid && res.error === "No user profile") {
          this.$router.push("/profile/create");
        }
      }
    });

    this.$store.dispatch("setProfilesRef", db.collection("profile"));
    this.$store.dispatch("setServicesRef", db.collection("services"));
    this.$store.dispatch("setMessagesRef", db.collection("messages"));
    this.$store.dispatch("setBlogsRef", db.collection("blogs"));
    this.$store.dispatch("setUnpublishedBlogsRef", db.collection("blogs"));
  },
};
</script>

<style>
.text-blue {
  color: #023b95;
}

.text-secondary-blue {
  color: #3c6096;
}

.underline {
  text-decoration: underline #023b95;
}

.btn-blue {
  background-color: #023b95;
  color: white;
  width: 50%;
  border-radius: 30px;
  padding: 10px;
}

.btn-blue:hover {
  background-color: #023b95;
  color: white;
  width: 50%;
  border-radius: 30px;
  padding: 10px;
}
</style>

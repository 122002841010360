<template>
  <div>
    <br /><br /><br /><br />
    <div v-if="getUser === null || getProfile === null" class="container">
      <login />
    </div>
    <div v-else class="container">
      <messages v-if="getProfile.roles.includes('admin')" />
      <h2 v-else class="text-blue text-center">Welcome</h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Login from "../components/Login.vue";
import Messages from "../components/Messages/Messages.vue";

export default {
  name: "Home",
  components: { Login, Messages },
  computed: {
    ...mapGetters(["getUser", "getProfile"]),
  },
};
</script>

<template>
  <div class="card mb-4">
    <div class="card-body">
      <h5 class="card-title text-blue">{{ message.name }}</h5>
      <small class="card-text text-secondary">{{ message.email }}</small>
      <br />
      <small class="card-text text-secondary">{{ date }}</small>
      <p class="card-text text-secondary-blue">{{ message.message }}</p>
      <button class="btn btn-outline-success" @click.prevent="markRead">
        SEEN
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MessageCard",
  computed: {
    ...mapGetters(["getProfile"]),
    date() {
      const date = new Date(this.message.createdAt);
      const dateString = date.toDateString().slice(4).split(" ");
      const timeString = date.toTimeString().slice(0, 5);
      const formattedDate = `${dateString[1]} ${dateString[0]}, ${dateString[2]}  at ${timeString}`;
      return formattedDate;
    },
  },
  data() {
    return { error: "", disabled: false };
  },
  props: {
    message: Object,
  },
  methods: {
    async markRead() {
      const updatedMessage = {
        id: this.message.id,
        body: {
          seen: true,
          seenBy: this.getProfile.name,
          seenAt: new Date(),
        },
      };
      const res = await this.$store.dispatch("updateMessage", updatedMessage);
      if (!res.isValid) {
        this.disabled = false;
        this.error = "An error has occured";
        return;
      }
    },
  },
};
</script>

<style scoped>
</style>

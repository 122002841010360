import { firestoreAction } from "vuexfire";

import { db } from "../../firebase";

const messagesRef = db.collection("messages");

const state = {
  messages: [],
};

const getters = {
  getMessages(state) {
    return state.messages;
  },
};

const actions = {
  setMessagesRef: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      "messages",
      messagesRef.where("seen", "==", false).orderBy("createdAt", "desc")
    );
  }),
  async updateMessage(context, updatedMessage) {
    try {
      await messagesRef.doc(updatedMessage.id).update(updatedMessage.body);
      return { isValid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
};

const mutations = {};

export default { state, getters, actions, mutations };

import { firestoreAction } from "vuexfire";

import { auth, db } from "../../firebase";

const profileRef = db.collection("profile");

const state = {
  user: null,
  profile: null,
  profiles: [],
  writerProfile: null,
};

const getters = {
  getUser(state) {
    return state.user;
  },
  getProfile(state) {
    return state.profile;
  },
  getProfiles(state) {
    return state.profiles;
  },
  getWriterProfile(state) {
    return state.writerProfile;
  },
};

const actions = {
  setProfilesRef: firestoreAction((context) => {
    return context.bindFirestoreRef("profiles", profileRef);
  }),
  async register({ commit }, user) {
    try {
      const userData = await auth.createUserWithEmailAndPassword(
        user.email,
        user.password
      );
      commit("userStatus", userData.user);
      return { isValid: true };
    } catch (error) {
      commit("userStatus", null);
      return { isValid: false, error: error.message };
    }
  },
  async signIn({ commit }, user) {
    try {
      const userData = await auth.signInWithEmailAndPassword(
        user.email,
        user.password
      );
      const userInfo = { uid: userData.user.uid, email: userData.user.email };
      commit("userStatus", userInfo);
      return { isValid: true };
    } catch (error) {
      commit("userStatus", null);
      return { isValid: false, error: error.message };
    }
  },
  async signOut({ commit }) {
    try {
      await auth.signOut();
      commit("userStatus", null);
      commit("userProfile", null);
    } catch (error) {
      commit("userStatus", null);
    }
  },
  async createProfile({ commit }, user) {
    try {
      await profileRef.doc(user.uid).set(user.profile);
      commit("userProfile", user.profile);
      return { isValid: true };
    } catch (error) {
      commit("userProfile", null);
      return { isValid: false, error: error.message };
    }
  },
  async updateProfile({ commit, state }, user) {
    try {
      await profileRef.doc(user.uid).update(user.profile);
      const profile = { ...state.profile, ...user.profile };
      commit("userProfile", profile);
      return { isValid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
  async getProfile({ commit }, uid) {
    try {
      const snapshot = await profileRef.doc(uid).get();
      if (!snapshot.exists) {
        commit("userProfile", null);
        return { isValid: false, error: "No user profile" };
      }

      commit("userProfile", snapshot.data());
      return { isValid: true };
    } catch (error) {
      commit("userProfile", null);
      return { isValid: false, error: error.message };
    }
  },
  async getWriterProfile({ commit }, uid) {
    try {
      const snapshot = await profileRef.doc(uid).get();
      if (!snapshot.exists) {
        commit("writerProfile", null);
        return;
      }

      commit("writerProfile", snapshot.data());
    } catch (error) {
      commit("writerProfile", null);
    }
  },
};

const mutations = {
  userStatus(state, user) {
    user !== null ? (state.user = user) : (state.user = null);
  },
  userProfile(state, profile) {
    profile !== null ? (state.profile = profile) : (state.profile = null);
  },
  writerProfile(state, profile) {
    profile !== null
      ? (state.writerProfile = profile)
      : (state.writerProfile = null);
  },
};

export default { state, getters, actions, mutations };

import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/services",
    component: () => import("../views/Services.vue"),
    children: [
      {
        path: "",
        name: "AllServices",
        component: () => import("../components/Services/AllServices.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }
          next();
        },
      },
      {
        path: "new",
        name: "AddService",
        component: () => import("../components/Services/AddService.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getProfile.roles.includes("admin")) {
            next("/services");
          }
          next();
        },
      },
      {
        path: "edit",
        name: "EditService",
        component: () => import("../components/Services/EditService.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getProfile.roles.includes("admin")) {
            next("/services");
          }

          if (!store.getters.getService) {
            next("/services");
          }

          next();
        },
      },
      {
        path: ":serviceId",
        name: "ViewService",
        component: () => import("../components/Services/Service.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getService) {
            next("/services");
          }

          next();
        },
      },
    ],
  },
  {
    path: "/profile",
    component: () => import("../views/Profile.vue"),
    children: [
      {
        path: "",
        name: "MyProfile",
        component: () => import("../components/Profile/MyProfile.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getProfile) {
            next("/profile/create");
          }

          next();
        },
      },
      {
        path: "all",
        name: "AllProfiles",
        component: () => import("../components/Profile/AllProfiles.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getProfile) {
            next("/profile/create");
          }

          if (!store.getters.getProfile.roles.includes("admin")) {
            next("/");
          }

          next();
        },
      },
      {
        path: "create",
        name: "CreateProfile",
        component: () => import("../components/Profile/CreateProfile.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser || store.getters.getProfile) {
            next("/");
          }

          const allowed = ["/", "/register"];
          if (!allowed.includes(from.fullPath)) {
            next("/");
          }

          next();
        },
      },
      {
        path: "edit",
        name: "EditProfile",
        component: () => import("../components/Profile/EditProfile.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getProfile) {
            next("/profile/create");
          }

          next();
        },
      },
    ],
  },
  {
    path: "/blogs",
    component: () => import("../views/Blogs.vue"),
    children: [
      {
        path: "",
        name: "MyBlogs",
        component: () => import("../components/Blogs/MyBlogs.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          next();
        },
      },
      {
        path: "new",
        name: "CreateBlog",
        component: () => import("../components/Blogs/CreateBlog.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          next();
        },
      },
      {
        path: "edit",
        name: "EditBlog",
        component: () => import("../components/Blogs/EditBlog.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getBlog) {
            next("/blogs");
          }

          next();
        },
      },
      {
        path: "pending",
        name: "PendingBlogs",
        component: () => import("../components/Blogs/PendingBlogs.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getProfile.roles.includes("admin")) {
            next("/blogs");
          }

          next();
        },
      },
      {
        path: ":blogId",
        name: "Blog",
        component: () => import("../components/Blogs/Blog.vue"),
        beforeEnter(to, from, next) {
          if (!store.getters.getUser) {
            next("/");
          }

          if (!store.getters.getBlog) {
            next("/blogs");
          }

          next();
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

import { firestoreAction } from "vuexfire";

import { db } from "../../firebase";

const servicesRef = db.collection("services");

const state = {
  services: [],
  service: null,
};

const getters = {
  getServices(state) {
    return state.services;
  },
  getService(state) {
    return state.service;
  },
};

const actions = {
  setServicesRef: firestoreAction((context) => {
    return context.bindFirestoreRef("services", servicesRef);
  }),
  async createService(context, service) {
    try {
      await servicesRef.add(service);
      return { isValid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
  async updateService(context, updatedService) {
    try {
      await servicesRef.doc(updatedService.id).update(updatedService.service);
      return { isValid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
  async deleteService(context, serviceId) {
    try {
      await servicesRef.doc(serviceId).delete();
      return { isvalid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
};

const mutations = {
  serviceStatus(state, service) {
    service !== null ? (state.service = service) : (state.service = null);
  },
};

export default { state, getters, actions, mutations };

import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";

import auth from "./modules/auth";
import blogs from "./modules/blogs";
import messages from "./modules/messages";
import services from "./modules/services";

Vue.use(Vuex);

export default new Vuex.Store({
  mutations: vuexfireMutations,
  modules: {
    auth,
    blogs,
    messages,
    services,
  },
});

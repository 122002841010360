<template>
  <div class="container">
    <form>
      <h3 class="text-center" style="color: #023b95">Sign In</h3>
      <small v-if="error" class="text-danger">{{ error }}</small>
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          aria-describedby="emailHelp"
          placeholder="Email"
          v-model="email"
        />
        <small v-if="emailError" class="text-danger">{{ emailError }}</small>
      </div>
      <br />
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="Password"
          v-model="password"
        />
        <small v-if="passwordError" class="text-danger">{{
          passwordError
        }}</small>
      </div>
      <br />
      <div class="text-center">
        <button
          class="btn"
          :class="disabled ? 'disabled' : ''"
          @click.prevent="signIn"
        >
          Sign In
        </button>
      </div>
    </form>
    <br />
    <div class="text-center">
      <small
        >Don't have an account?
        <router-link to="/register">Register</router-link></small
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      error: "",
      email: "",
      emailError: "",
      password: "",
      passwordError: "",
      disabled: false,
    };
  },
  methods: {
    ...mapGetters(["getUser"]),
    async signIn() {
      this.error = "";
      this.emailError = "";
      this.passwordError = "";
      this.disabled = true;

      let errors = 0;

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        errors++;
        this.emailError = "Invalid email.";
      }

      if (!this.password.length) {
        errors++;
        this.passwordError = "Password can not be empty.";
      }

      if (errors > 0) {
        this.disabled = false;
        return;
      }

      const user = { email: this.email, password: this.password };

      const res = await this.$store.dispatch("signIn", user);
      if (!res.isValid) {
        this.error = "Invalid credentials";
        this.disabled = false;
        return;
      }

      const resp = await this.$store.dispatch("getProfile", this.getUser().uid);
      if (!resp.isValid && resp.error === "No user profile") {
        this.disabled = false;
        this.$router.push("/profile/create");
        return;
      }

      if (!resp.isValid) {
        this.error = "An error has occured";
        this.disabled = false;
        return;
      }

      this.error = "";
      this.email = "";
      this.emailError = "";
      this.password = "";
      this.passwordError = "";
      this.disabled = false;
    },
  },
};
</script>

<style scoped>
.btn {
  width: 50% !important;
  padding: 10px;
  font-size: 20px;
  background-color: #023b95;
  border-radius: 30px;
  color: white;
}

.container {
  width: 80%;
}

a {
  color: #023b95;
}

input {
  border: solid 2px #023b95;
  border-radius: 8px;
  padding: 10px;
  font-size: 18px;
}
</style>

<template>
  <nav class="navbar navbar-expand-lg navbar-dark navbar-blue">
    <div class="container">
      <router-link class="navbar-brand" to="/">SGC</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav ml-auto">
          <template v-for="(item, index) in navItems">
            <li v-if="item.children" class="nav-item dropdown" :key="index">
              <a
                class="nav-link dropdown-toggle text-white"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ item.name }}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link
                  class="dropdown-item text-blue"
                  v-for="(child, index) in item.children"
                  :key="index"
                  :to="child.to"
                  >{{ child.name }}</router-link
                >
              </div>
            </li>
            <li v-else class="nav-item" :key="index">
              <router-link class="nav-link text-white" :to="item.to">{{
                item.name
              }}</router-link>
            </li>
          </template>
          <button
            v-if="getUser"
            class="btn btn-logout text-white"
            @click.prevent="signOut"
          >
            Log Out
          </button>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopNav",
  computed: {
    ...mapGetters(["getUser", "getProfile"]),
    navItems() {
      if (!this.getUser) {
        return [];
      }

      if (!this.getProfile) {
        return [];
      }

      if (this.getProfile.roles.includes("admin")) {
        return this.admin;
      }

      return this.user;
    },
  },
  data() {
    return {
      admin: [
        {
          name: "Blogs",
          children: [
            { name: "My Blogs", to: "/blogs" },
            { name: "Pending Blogs", to: "/blogs/pending" },
            { name: "Write Blog", to: "/blogs/new" },
          ],
        },
        {
          name: "Services",
          children: [
            { name: "All Services", to: "/services" },
            { name: "Create Service", to: "/services/new" },
          ],
        },
        {
          name: "Profiles",
          children: [
            { name: "My Profile", to: "/profile" },
            { name: "All Profiles", to: "/profile/all" },
          ],
        },
      ],
      user: [
        {
          name: "Blogs",
          children: [
            { name: "My Blogs", to: "/blogs" },
            { name: "Write Blog", to: "/blogs/new" },
          ],
        },
        {
          name: "Services",
          to: "/services",
        },
        {
          name: "Profile",
          to: "/profile",
        },
      ],
    };
  },
  methods: {
    async signOut() {
      await this.$store.dispatch("signOut");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.btn-lougout {
  background-color: #023b95;
}

.dropdown-item:hover {
  color: #023b95 !important;
}

.navbar-blue {
  background-color: #023b95;
}
</style>

import { firestoreAction } from "vuexfire";

import { db } from "../../firebase";

const blogsRef = db.collection("blogs");

const state = {
  blogs: [],
  unpublishedBlogs: [],
  myBlogs: [],
  blog: null,
};

const getters = {
  getBlogs(state) {
    return state.blogs;
  },
  getUnpublishedBlogs(state) {
    return state.unpublishedBlogs;
  },
  getBlog(state) {
    return state.blog;
  },
  getMyBlogs(state) {
    return state.myBlogs;
  },
};

const actions = {
  setBlogsRef: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      "blogs",
      blogsRef.where("published", "==", true).orderBy("publishDate", "asc")
    );
  }),
  setUnpublishedBlogsRef: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      "unpublishedBlogs",
      blogsRef.where("published", "==", false).orderBy("createdAt", "desc")
    );
  }),
  async createBlog(context, blog) {
    try {
      await blogsRef.add(blog);
      return { isValid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
  async updateBlog(context, updatedBlog) {
    try {
      await blogsRef.doc(updatedBlog.id).update(updatedBlog.body);
      return { isValid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
  async deleteBlog(context, blogId) {
    try {
      await blogsRef.doc(blogId).delete();
      return { isValid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
  async getMyBlogs({ commit }, uid) {
    try {
      const snapshot = await blogsRef.where("writtenBy", "==", uid).get();
      if (snapshot.empty) {
        commit("myBlogs", []);
        return { isValid: false, error: "You have no blogs." };
      }
      let myBlogs = [];
      snapshot.forEach((doc) => {
        myBlogs.push({ id: doc.id, ...doc.data() });
      });
      commit("myBlogs", myBlogs);
      return { isValid: true };
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  },
};

const mutations = {
  blogStatus(state, blog) {
    blog !== null ? (state.blog = blog) : (state.blog = null);
  },
  myBlogs(state, myBlogs) {
    myBlogs.length ? (state.myBlogs = myBlogs) : (state.myBlogs = []);
  },
};

export default { state, getters, actions, mutations };
